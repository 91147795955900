import { render, staticRenderFns } from "./RelatorioControleRecebimentoForm.vue?vue&type=template&id=d53a90dc&scoped=true&"
import script from "./RelatorioControleRecebimentoForm.vue?vue&type=script&lang=js&"
export * from "./RelatorioControleRecebimentoForm.vue?vue&type=script&lang=js&"
import style0 from "./RelatorioControleRecebimentoForm.vue?vue&type=style&index=0&id=d53a90dc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d53a90dc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VDivider,VRadio,VRadioGroup,VRow,VTextField})
